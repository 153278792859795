<template>
	<w-layout v-if="template !== null && !loading" fill-height overflow-hidden class="doctemplates template-editor">
		<v-navigation-drawer
			v-if="showSidebar"
			transition="slide-x-transition"
			right
			:style="{ 'min-width': '30vw' }"
			color="primary"
			permanent
			overflow-hidden
			fill-height
		>
			<v-layout column fill-height class="py-4">
				<v-flex shrink>
					<v-layout row justify-center class="pl-4">
						<v-flex row class="pt-2">
							<v-flex title>{{ $t('doctemplates.editor.title') }}</v-flex>
							<w-text-input v-model="template.name" :placeholder="$t('doctemplates.editor.name')" :rules="[rules.required]" autofocus required />
						</v-flex>
						<v-flex v-if="canGoToList" shrink>
							<v-layout justify-right align-start>
								<v-btn icon @click="showSidebar = false">
									<v-icon color="secondary">keyboard_arrow_left</v-icon>
								</v-btn>
							</v-layout>
						</v-flex>
					</v-layout>
				</v-flex>
				<v-flex shrink class="px-4">
					<w-search-input v-model="search_symbols" :disabled="loadingSymbols" :placeholder="$t('doctemplates.editor.symbols.search')" />
				</v-flex>
				<v-flex v-if="!loadingSymbols" scroll-y class="px-3">
					<div v-for="symbolsCategory of filtered_symbols_displayed" :key="symbolsCategory.name" class="mt-2 mb-4">
						<p
							v-if="Object.keys(symbols).length >= 1"
							class="px-2 subheading font-weight-medium"
							:class="{ 'grey--text text--darken-3': !darkMode, 'white--text': darkMode }"
						>
							{{ $t('doctemplates.symbols.' + symbolsCategory.name + '._group_name') }}
						</p>
						<v-btn v-for="symbol of symbolsCategory.symbols" :key="symbol.name" small @click="() => addSymbol(symbol.name, symbol.type)"
							>{{ symbol.label }}
						</v-btn>
					</div>
				</v-flex>
				<w-layout v-else fill-height full-width justify-center align-center>
					<v-flex class="mt-2 px-3" text-xs-center>
						<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
					</v-flex>
				</w-layout>
			</v-layout>
		</v-navigation-drawer>
		<v-divider vertical></v-divider>
		<v-layout column fill-height>
			<v-flex shrink>
				<w-layout align-center row class="py-2 pr-3" :class="{ 'pl-4': showSidebar }">
					<v-btn v-if="!showSidebar" icon @click="showSidebar = true">
						<v-icon color="secondary">keyboard_arrow_right</v-icon>
					</v-btn>
					<w-text-input v-model="template.object" :placeholder="$t('doctemplates.editor.object')" class="mr-4" :rules="[rules.required]" required />
					<w-switch
						v-model="showPreview"
						shrink
						:disabled="!canSave"
						color="primary"
						hide-details
						class="mt-0 mr-4 ml-2 switch-mode"
						:label="!showPreview ? $t('preview') : $t('actions.edit')"
					/>
					<w-btn-save class="mr-2" :disabled="!canSave" :flat="false" @click="saveTemplate" />
					<v-btn v-if="original_template !== null" class="ml-1" round color="warning" :disabled="!canSave || !hasChange" @click="restoreTemplate">
						<v-icon class="mr-2" dark>restore</v-icon>
						{{ $t('actions.cancel') }}
					</v-btn>
				</w-layout>
			</v-flex>
			<v-divider></v-divider>
			<v-progress-linear v-if="showPreviewIsChanging" class="my-0" height="4" color="primary" indeterminate></v-progress-linear>
			<v-flex v-if="showPreview && fileSrc" row class="editor-container">
				<PDFViewer :src="fileSrc" :display-thumbnails="true" :page.sync="currentPage" zoom-buttons />
			</v-flex>
			<v-flex v-show="!(showPreview && fileSrc !== null)" fill-height class="editor-container">
				<v-alert :value="missingSymbols" type="warning" class="warning">
					{{ $t('doctemplates.symbols.errors.missing') }}
				</v-alert>
				<w-rich-text-editor ref="editor" v-model="template.template" mode="full" skin-content="document" @change="templateChecker"></w-rich-text-editor>
			</v-flex>
		</v-layout>
	</w-layout>
	<w-layout v-else fill-height full-width justify-center align-center>
		<v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
	</w-layout>
</template>

<script>
import { mapState } from 'vuex'
import DocumentTemplateModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentTemplateModuleGuard'

const defaultLogo =
	'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAcHBwcIBwgJCQgMDAsMDBEQDg4QERoSFBIUEhonGB0YGB0YJyMqIiAiKiM+MSsrMT5IPDk8SFdOTldtaG2Pj8ABBwcHBwgHCAkJCAwMCwwMERAODhARGhIUEhQSGicYHRgYHRgnIyoiICIqIz4xKysxPkg8OTxIV05OV21obY+PwP/CABEIAGQAZAMBIgACEQEDEQH/xAAcAAEAAgIDAQAAAAAAAAAAAAAABgcEBQEDCAL/2gAIAQEAAAAA9IgAAAAAAAAABxUtsUVkT+Ed89qvIn8x+aZsSH2nRHfIdfm2lR15ceYbmq/dTautnsofu5tOAAAAAAAAAAAf/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oACAECEAAAAAABLFEagAAAf//EABYBAQEBAAAAAAAAAAAAAAAAAAABAv/aAAgBAxAAAAAAAAWLi0AAAf/EADEQAAEEAQMCAwYFBQAAAAAAAAECAwQFBgAHEhEhEzFBECIjM1GSFBU1UmBhcnWCsv/aAAgBAQABPwD+An11tDZWM+ru1zZz8lSLNxCC84XCEgDt30taUJUtaglKQSSewAGo1vl+4k+eKa0NRRRnC0JCE9Xnjqzw/PsfjO2VNmU2wWyjkuJL6uBYH05lWsGyxrK8fZn8A2+lRbfaHklwatsqyXJMklY7ijrcZmJ2m2Shz4f0Robc5W0A4zuLal8fvBW19hXrHE5HFqSjI5Md2U04v4zXupW36KPYAHUa3y/cSfPFNZmoo4zhaEhCerzx1ZYfn1BGdsqXMptgtlHJcWX1cCwPpzKtYNljWV4+zP4Bt9Ki2+0PJLg9h8jrZT9Ivv8ALO/8jWYLcbxS/W2SFivkEEf2HWzaGk4JAKB3U6+V/edHWz3NqLmHg/JROPg62KCF0Fu+e77lifEP+g1f2e6USbPVWVdQ5XN923HSvmUAevRY1VZZZZLtpf2clppmSmNMR8AED3Ea2bQ0nBIBQO6nXyv79Hy1sskoTlSW/kCx6N+w+R1t5uBj2KxrmHafiA65YuOANt8tUGYY5mrFlEg+OUobCXg4jh1S6CNUVpbbYPTaq5rZUmmU8XI01hHMJ1ZbqfncN6BiVTPlz3xwS6WujbPL1Otv8UOLY6zBeWlclxZekqT5c1acgX+3eR2NjX1jthRT183mme62NTN5MamQn40ODZvSnW1oDAYHIE62soJsTCHINrDcYMl14lpY4r4OAJ1RWlvte9OqrmslSqdTpcjTWEcwnlqz3VF3DegYlUz5c98FAdLXRtnl6nW32KHFseZhPKSuS4svSVJ/er+B/wD/xAAmEQABBAECBAcAAAAAAAAAAAABAAIRIUESMRAiUWEwQGJxcpGh/9oACAECAQE/APGFkjoJWYCoz23Q2lZgXlC5jG/Bp5n/AAr7Qpz5rU0QoJD8S2AnGWhzd9MR3CoPeOrW/iaLcfTHvfl//8QAJxEAAgIBAgILAAAAAAAAAAAAAREAAiExYRBBEiIwMkBCUmKBgpH/2gAIAQMBAT8A7bk91OTMOFvDqnmDRnEOFvwPc+whzWq8ti/kRro8+s4ArEH1NwM0rte34YdKj3Pw/wD/2Q=='

export default {
	name: 'DocumentTemplatesEditor',
	components: {
		PDFViewer: () => ({
			component: import('@/components/Documents/Preview/Viewers/PDFViewer')
		})
	},
	mixins: [DocumentTemplateModuleGuard],
	props: {
		documentId: {
			type: Number,
			default: null
		}
	},
	data: function () {
		return {
			loading: false,
			loadingSymbols: false,
			missingSymbols: false,
			checkSymbols: null,
			showSidebar: true,
			showPreview: false,
			canGoToList: true,
			showPreviewIsChanging: false,
			rules: {},
			template: null,
			original_template: null,
			fileSrc: null,
			symbols: {},
			search_symbols: '',
			currentPage: 0,
			i18nBasePath: 'doctemplates.symbols.'
		}
	},
	computed: {
		...mapState({
			accountingFirm: state => state.accountingFirm.selected,
			darkMode: state => state.user.darkMode
		}),
		canSave: function () {
			return (
				!this.missingSymbols &&
				this.template.name != null &&
				(this.template.name + '').trim().length > 0 &&
				this.template.object != null &&
				(this.template.object + '').trim().length > 0 &&
				this.template.template != null &&
				(this.template.template + '').trim().length > 0
			)
		},
		hasChange: function () {
			return JSON.stringify(this.template) !== JSON.stringify(this.original_template)
		},
		symbols_displayed: function () {
			let symbols_displayed = {}
			for (const [name, type] of Object.entries(this.symbols)) {
				let prefix = name.split('.')[0]
				if (!symbols_displayed[prefix]) {
					symbols_displayed[prefix] = {
						name: prefix,
						label: this.$t(this.i18nBasePath + prefix + '._group_name'),
						symbols: {}
					}
				}
				symbols_displayed[prefix]['symbols'][name] = {
					type: type,
					name: name,
					label: this.$t(this.i18nBasePath + name + (type === 'array' ? '.array' : ''))
				}
			}
			return symbols_displayed
		},
		filtered_symbols_displayed: function () {
			let search = this.search_symbols === null ? '' : (this.search_symbols + '').trim().toLowerCase()
			if (search.length === 0) {
				return this.symbols_displayed
			}
			let symbols = {}
			for (const [categoryKey, categoryValue] of Object.entries(this.symbols_displayed)) {
				symbols[categoryKey] = this.cloneObject(categoryValue)
				symbols[categoryKey]['symbols'] = {}

				if (symbols[categoryKey]['label'].toLowerCase().includes(search)) {
					symbols[categoryKey]['symbols'] = this.cloneObject(categoryValue.symbols)
				} else {
					for (const [symbolKey, symbol] of Object.entries(categoryValue.symbols)) {
						if (symbol.name.toLowerCase().includes(search) || symbol.label.toLowerCase().includes(search)) {
							symbols[categoryKey]['symbols'][symbolKey] = symbol
						}
					}
				}
			}
			return symbols
		}
	},
	watch: {
		accountingFirm: {
			handler: function () {
				this.goToTemplateList(true)
			}
		},
		showPreview: function (newVal, oldVal) {
			if (!this.canSave) {
				this.showPreview = this.oldVal
			} else {
				this.showPreviewIsChanging = true
				if (oldVal !== newVal && newVal) {
					this.$refs.editor.sanitize()
					this.templateCleaner()

					let newTemplate = Object.assign({}, this.template)
					newTemplate.template = this.formatTemplate(newTemplate)
					newTemplate.locale = this.$i18n.locale
					return this.service
						.dummyDocTemplate(newTemplate)
						.then(response => {
							if (this.fileSrc) {
								URL.revokeObjectURL(this.fileSrc)
							}
							const binary = atob(response.replace(/\s/g, ''))
							let buffer = new ArrayBuffer(binary.length)
							let view = new Uint8Array(buffer)
							for (let i = 0; i < binary.length; i++) {
								view[i] = binary.charCodeAt(i)
							}
							const blob = new Blob([view], { type: 'application/pdf' })
							this.fileSrc = URL.createObjectURL(blob)
							this.showPreviewIsChanging = false
						})
						.catch(() => {
							this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, this.$t('doctemplates.errors.dummy_preview_failed'))
							this.showPreview = false
						})
				} else {
					this.fileSrc = null
					this.showPreviewIsChanging = false
				}
			}
		},
		documentId: {
			handler: function (val) {
				try {
					if (val === null) {
						this.template = {
							name: '',
							object: '',
							template: ''
						}
					} else if ((val + '').trim() === '') {
						throw new Error('Fail to identify document template id')
					} else {
						this.loading = true
						this.service
							.getDocTemplate(val)
							.then(template => {
								this.loading = false
								this.template = template
								this.template.template = this.unformatTemplate(this.template)
								this.original_template = this.cloneObject(this.template)
							})
							.catch(e => {
								let err = new Error('Fail to fetch document template')
								err.stack += '\nCaused by: ' + e.stack
								throw err
							})
					}
				} catch (e) {
					this.appService.goTo('doc-templates', true)
				}
			},
			immediate: true
		}
	},
	created: function () {
		this.loadSymbols()
	},
	mounted () {
		this.service.setRules({
			required: this.$t('doctemplates.errors.missing')
		})
		this.rules = this.service.getRules()
	},
	methods: {
		loadSymbols: async function () {
			this.loadingSymbols = true
			this.symbols = await this.service.getAllSymbols()
			this.loadingSymbols = false
		},
		goToTemplateList: function (redirect = false) {
			this.appService.goTo(`doc-templates`, redirect)
		},
		saveTemplate: function () {
			if (!this.canSave) {
				return
			}
			this.loading = true
			this.$refs.editor.sanitize()
			this.templateCleaner()
			let newTemplate = Object.assign({}, this.template)
			newTemplate.template = this.formatTemplate(newTemplate)
			newTemplate.locale = this.$i18n.locale
			const req = this.template?.id ? this.service.updateDocTemplate(this.template.id, newTemplate) : this.service.createDocTemplate(newTemplate)
			return req.then(template => {
				this.template = template
				this.template.template = this.unformatTemplate(this.template)
				this.original_template = this.cloneObject(this.template)
				this.showPreview = false

				this.appService.goTo('doc-templates')
			})
		},
		restoreTemplate: function () {
			this.template = this.cloneObject(this.original_template)
			this.showPreview = false
		},
		insertOnCursorPosition: function (element) {
			try {
				this.$refs.editor.getEditor().execCommand('mceInsertContent', false, element)
			} catch (e) {
				this.template.template += element
			}
		},
		addSymbol: function (symbol_name, symbol_type) {
			if (symbol_type === 'array') {
				this.insertOnCursorPosition(`<p>{{#${symbol_name}}}</p><p>{{/${symbol_name}}}</p>`)
			} else if (symbol_type === 'img') {
				this.insertOnCursorPosition(`<img src="` + defaultLogo + `" alt="{{ ${symbol_name} }}" width="50" height="50" />`)
			} else if (symbol_name.indexOf('*.') !== -1) {
				if (symbol_type === 'html') {
					this.insertOnCursorPosition(`{{{ ${symbol_name.split('*.')[1]} }}}`)
				} else {
					this.insertOnCursorPosition(`{{ ${symbol_name.split('*.')[1]} }}`)
				}
			} else {
				this.insertOnCursorPosition(`{{ ${symbol_name} }}`)
			}
		},
		formatTemplate: function (template) {
			let newTemplate = template.template
			const parser = new DOMParser()
			let htmlDoc = parser.parseFromString(newTemplate, 'text/html')
			let imgElements = htmlDoc.getElementsByTagName('img')
			for (let i = 0; i < imgElements.length; i++) {
				if (imgElements[i].hasAttribute('alt') && imgElements[i].getAttribute('alt') === '{{ accounting_firm.logo }}') {
					imgElements[i].setAttribute('src', '{{ accounting_firm.logo }}')
				}
			}
			return htmlDoc.documentElement.innerHTML
		},
		unformatTemplate: function (template) {
			let newTemplate = template.template
			const imgSrc = 'src="{{'
			while (newTemplate.indexOf(imgSrc) != -1) {
				let tagPos = newTemplate.indexOf(imgSrc) + 'src="'.length
				let endTagPos = newTemplate.indexOf('}}', tagPos) + '}}'.length
				let startString = newTemplate.substring(0, tagPos)
				let endString = newTemplate.substring(endTagPos)
				newTemplate = startString + defaultLogo + endString
			}
			return newTemplate
		},
		templateCleaner: function () {
			let el = document.createElement('html')
			el.innerHTML = this.template.template
			let iterateFn = function (parentNode) {
				parentNode.childNodes.forEach(function (node) {
					const regexTag = /\s*?({{\s*?[#|/].*\s*?}})\s?/g
					const regexHTML = /(<([^>]+)>)/gi
					if (node.childNodes.length >= 1) {
						let found = [...node.innerHTML.trim().matchAll(regexTag)]
						if (found.length >= 1 && node.innerHTML.trim().replace(regexHTML, '').trim() === found[0][0]) {
							node.parentElement.innerHTML = node.parentElement.innerHTML.replaceAll(node.outerHTML, found[0][0])
						} else {
							iterateFn(node)
						}
					}
				})
			}
			iterateFn(el.getElementsByTagName('body')[0])
			let lastElement = el.getElementsByTagName('body')[0].lastElementChild
			if (lastElement.outerHTML.trim() !== '<p>&nbsp;</p>') {
				let e = document.createElement('p')
				e.innerHTML = '&nbsp;'

				el.getElementsByTagName('body')[0].appendChild(e)
			}

			this.template.template = el.outerHTML
		},
		templateChecker: function () {
			if (this.checkSymbols !== null) {
				clearTimeout(this.checkSymbols)
				this.checkSymbols = null
			}
			this.checkSymbols = setTimeout(() => {
				if (this.showPreview) {
					return
				}
				const regex_start_tag = /{{#([^}]*)}}/gm
				const regex_end_tag = /{{\/([^}]*)}}/gm
				let data = this.$refs.editor?.getSanitizedContent()
				if (typeof data === 'undefined') {
					return
				}
				let start_tags = [...data.matchAll(regex_start_tag)]
					.map(e => {
						return e[1]
					})
					.filter(e => {
						return e.trim() !== '' && e !== null && typeof e !== 'undefined'
					})
				let end_tags = [...data.matchAll(regex_end_tag)]
					.map(e => {
						return e[1]
					})
					.filter(e => {
						return e.trim() !== '' && e !== null && typeof e !== 'undefined'
					})

				this.missingSymbols =
					[...start_tags.filter(e => !end_tags.includes(e)), ...end_tags.filter(e => !start_tags.includes(e))].length >= 1 ||
					start_tags.length !== end_tags.length
			}, 200)
		}
	}
}
</script>

<style>
/** Override editor document template style **/
.doctemplates.template-editor .editor-container {
	position: relative;
	border: none;
	display: flex;
	flex-direction: column;
}

.doctemplates.template-editor .editor-container > .tox {
	height: auto !important;
	flex: 1 2 auto;
}

.doctemplates.template-editor .editor-container > .v-alert {
	width: 100%;
	margin: 0;
	border: none;
	padding: 10px;
}

.doctemplates.template-editor .switch-mode {
	flex: 0 1 auto;
}

.doctemplates.template-editor .pdf-viewer .pdf-viewer__document,
.doctemplates.template-editor .pdf-viewer .pdf-viewer__preview {
	top: 0 !important;
}
</style>
